<template>
  <div class="dialog-container other-dialog">
    <el-dialog
      :title="editOrCheck==='1' ? '设置产品销售方案' : editOrCheck==='2' ? '编辑产品销售方案' : '查看产品销售方案'"
      :visible.sync="showFlag"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      append-to-body
      top="15vh"
      width="900px"
    >
      <el-form ref="product" :model="product" class="detail-form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称:" class="mb0" prop="name">
              <!-- <el-input disabled v-model="product.name"> </el-input> -->
              {{ product.name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="产品类型:" class="mb0" prop="type">
              <!-- <el-input disabled v-model="product.type"> </el-input> -->
              {{ product.type }}
            </el-form-item>
          </el-col>
          <el-col v-if="product.cardType !== 3" :span="8">
            <el-form-item label="基础售价:" class="mb0" prop="type">
              <!-- <el-input disabled v-model="product.type"> </el-input> -->
              {{ product.basePrice }}
            </el-form-item>
          </el-col>
          <el-col :span="24"><el-divider style="margin-top: 15px;"></el-divider></el-col>
          <el-col :span="12">
            <el-form-item label="发售数量:" prop="inventoryQty">
              <el-input-number
                v-model="product.inventoryQty"
                :disabled="editOrCheck==='3'"
                controls-position="right"
                step-strictly
                style="width: 100%"
                :precision="0"
                :min="1"
                :max="999999999">
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发售栏目:" prop="columnId">
              <el-select
                v-model="product.columnId"
                :disabled="editOrCheck==='3'"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in productColumnge"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已购买基数:" prop="saleBaseQty">
              <el-input-number
                v-model="product.saleBaseQty"
                :disabled="editOrCheck==='3'"
                controls-position="right"
                style="width: 100%"
                step-strictly
                :precision="0"
                :min="0"
                :max="999999999">
              </el-input-number>
            </el-form-item>
          </el-col>
          <!-- <el-col v-if="product.cardType !== 3" :span="12">
            <el-form-item label="基础售价:" prop="basePrice">
              <el-input-number
                v-model="product.basePrice"
                controls-position="right"
                style="width: 100%"
                :precision="2"
                disabled>
              </el-input-number>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="产品标签:" prop="tag">
              <el-select
                v-model="product.tag"
                :disabled="editOrCheck==='3'"
                placeholder="请选择"
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in taggetList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="一句话文案:" class="mb0" prop="briefDesc">
              <el-input :maxlength="50" v-model="product.briefDesc" :disabled="editOrCheck==='3'"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24"><el-divider style="margin-top: 5px;"></el-divider></el-col>
          <template v-if="product.cardType !== 3">
            <el-col :span="24">
              <el-form-item label="是否降价:" prop="isPriceCut">
                <div class="df ai">
                  <div><el-switch v-model="product.isPriceCut" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0" :disabled="editOrCheck==='3'"> </el-switch></div>
                  <div class="f-cell margin-left-30">
                    原价:
                    <el-input-number
                      type="number"
                      v-model="product.marketPrice"
                      controls-position="right"
                      style="width: 240px; margin-right: 20px; margin-left:10px;"
                      :precision="2"
                      :disabled="editOrCheck==='3' || product.isPriceCut === 0"
                      :min="0"
                      :max="999999"
                      >
                    </el-input-number>
                    <template v-if="product.isPriceCut === 1">
                      降价后:
                      <el-input-number
                        v-model="product.mallPrice"
                        controls-position="right"
                        style="width: 240px; margin-left: 10px"
                        :precision="2"
                        :disabled="editOrCheck==='3'"
                        :min="0"
                        :max="999999">
                      </el-input-number>
                    </template>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="原价:" prop="marketPrice">
                <el-input-number
                  v-model="product.marketPrice"
                  controls-position="right"
                  style="width: 100%"
                  :precision="2"
                  :disabled="editOrCheck==='3' || product.isPriceCut === 0"
                  :min="0"
                  :max="999999999">
                </el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="product.isPriceCut === 1">
              <el-form-item label="降价后:" prop="mallPrice">
                <el-input-number
                  v-model="product.mallPrice"
                  controls-position="right"
                  style="width: 100%"
                  :precision="2"
                  :disabled="editOrCheck==='3'"
                  :min="0"
                  :max="999999999">
                </el-input-number>
              </el-form-item>
            </el-col> -->
          </template>
          <template>
            <el-col :span="6">
              <el-form-item label="是否赠送:" prop="giveMark">
                <el-switch :disabled="editOrCheck==='3'" v-model="product.giveMark" @change="giveChange" active-text="是" inactive-text="否" :active-value="1" :inactive-value="0"></el-switch>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <div class="isgive margin-left-30" v-if="product.giveMark===1">
                <el-col :span="24" v-for="(item, index) in product.giveDefList" :key="index">
                  <template v-if="product.cardType===3">
                  满：<el-input-number
                    v-model="item.fullMoney"
                    style="width: 23%; margin-right: 10px"
                    controls-position="right"
                    @change="handleChange"
                    :disabled="editOrCheck==='3'"
                    :precision="2"
                    :min="0.01"
                    :max="99999999"
                  ></el-input-number>
                  </template>
                  赠：<el-select
                    v-model="item.type"
                    placeholder="选择赠送类型"
                    style="width: 25%; margin: 0px 20px 0 0px"
                    class="my-select"
                    disabled
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                  <span v-if="product.cardType===3">
                    <el-input-number
                      v-model="item.giveMoney"
                      style="width: 25%; margin-right: 10px"
                      controls-position="right"
                      @change="handleChange"
                      :min="0"
                      :disabled="editOrCheck==='3'"
                      :max="9999999"
                    ></el-input-number>元
                  </span>
                  <span v-if="product.cardType===2">
                    <el-input-number
                      v-model="item.giveTicket"
                      style="width: 30%; margin-right: 10px"
                      controls-position="right"
                      @change="handleChange"
                      :min="0"
                      :max="9999999"
                      :disabled="editOrCheck==='3'"
                    ></el-input-number>次
                  </span>
                  <span v-if="product.cardType===1">
                    <el-input-number
                      v-model="item.giveDay"
                      style="width: 30%; margin-right: 10px"
                      controls-position="right"
                      @change="handleChange"
                      :min="0"
                      :max="9999999"
                      :disabled="editOrCheck==='3'"
                    ></el-input-number>天
                  </span>
                  <span v-if="product.cardType===3" class="operation">
                    <i class="el-icon-circle-plus-outline" @click="add(index)"></i>
                    <i class="el-icon-remove-outline" style="margin-left:5px" @click="relese(index)"></i>
                  </span>
                </el-col>
              </div>
            </el-col>
          </template>
        </el-row>
        <div class="line"></div>
      </el-form>
      <template slot="footer">
        <el-button v-if="editOrCheck!=='3'" class="footer-confirm" @click="save">提交</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{ editOrCheck==='3' ? '关闭' : '取消' }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";

export default {
  props: {
    editOrCheck: {
      require: true,
      type: String,
    },
  },
  data() {
    return {
      showFlag: false,
      product: {
        basePrice: '',
        briefDesc: '',
        cardType: '',
        columnId: '',
        giveMark: 0,
        inventoryQty: '',
        isPriceCut: 0,
        mallPrice: '',
        marketPrice: '',
        name: '',
        saleBaseQty: '',
        tag: [],
        type: '',
        giveDefList: [],
      },
      productColumnge: [],
      taggetList: [],
      options: [
        {
          value: 1,
          label: '权益',
        },
      ],
      rules: {
        columnId: [{ required: true, message: '请选择栏目', trigger: ['blur', 'change'] }],
      },
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueId", "venueList"]),
  },
  methods: {
    init() {
      if (this.$refs.product) {
        console.log(123);
        this.$refs.product.resetFields()
      }
    },
    // 获取栏目下拉框值
    productColumngetAll() {
      this.$http.get(apis.productColumngetAll).then((res) => {
        if (res.data.code === 0) {
          this.productColumnge = res.data.rows
        }
      })
    },
    // 获取标签下拉框值
    getproductTaggetList() {
      this.$http.get(apis.productTaggetAll).then((res) => {
        if (res.data.code === 0) {
          this.taggetList = res.data.data
        }
      })
    },
    // 是否赠送
    giveChange(value) {
      if (value === 0) {
        this.product.giveDefList = []
      } else {
        this.product.giveDefList = []
        this.product.giveDefList.push({
          fullMoney: '',
          giveDay: '',
          giveMoney: '',
          giveTicket: '',
          productGiftId: '',
          productId: '',
          name: '',
          type: 1,
        })
      }
    },
    add(index) {
      this.product.giveDefList.splice(index + 1, 0, {
        fullMoney: '',
        giveDay: '',
        giveMoney: '',
        giveTicket: '',
        productGiftId: '',
        productId: '',
        name: '',
        type: 1,
      })
    },
    relese(index) {
      this.product.giveDefList.splice(index, 1)
    },
    // 弹框打开触发
    showDialog() {
      this.showFlag = true;
    },
    getDetail(id) {
      this.$http.get(`${apis.productgetById}?id=${id}`).then((res) => {
        if (res.data.code === 0) {
          this.product = { ...this.product, ...res.data.data }
          console.log(this.product);
          if (this.product.type === 1) {
            this.product.type = '会员卡'
          }
          if (this.product.giveDefList && this.product.giveDefList.length > 0) {
            this.product.giveDefList.forEach((item) => {
              item.type = 1
            })
          } else {
            this.product.giveDefList = []
          }
          if (this.product.tag) {
            this.product.tag = this.product.tag.split(',')
          } else {
            this.product.tag = []
          }
          this.product.marketPrice = this.product.marketPrice || this.product.basePrice
          this.product.mallPrice = this.product.mallPrice || this.product.basePrice
          this.productColumngetAll()
          this.getproductTaggetList()
          this.showDialog()
        }
      })
    },
    // 保存
    save() {
      const formData = { ...this.product }
      console.log(formData, 56565)
      formData.tag = formData.tag.join(',')
      if (formData.type === '会员卡') {
        formData.type = 1
      }
      if (formData.isPriceCut === 0) {
        formData.mallPrice = formData.basePrice
        formData.marketPrice = formData.basePrice
      }
      this.$refs.product.validate((valid) => {
        if (valid) {
          this.$http.post(apis.productupdate, formData).then((res) => {
            if (res.data.code === 0) {
              this.$emit("save", false);
              this.closeDialog()
            }
          })
        }
      })
    },
    closeDialog() {
      this.init();
      this.$emit("closeDialog", false);
      this.$refs.product.resetFields();
      this.showFlag = false;
    },
    handleChange() {
      console.log(111);
    },
    change() {
      console.log(222);
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-form{ padding-top: 5px;}
.el-form-item__content {
  ul {
    list-style: none;
    display: flex;
    height: 40px;
    align-items: center;
    li {
      border: 1px solid skyblue;
      color: skyblue;
      line-height: 20px;
      margin-right: 10px;
      padding: 0px 10px;
      span {
        padding: 0px 10px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
/* 自定义宽度 */
.my-select {
  /* selecte 框的高度设置，默认是 40px*/
  .el-input__inner {
    height: 32px;
  }
  /* 下面设置右侧按钮居中 */
  .el-input__suffix {
    // top: 5px;
  }
  .el-input__icon {
    line-height: inherit;
  }
  .el-input__suffix-inner {
    display: inline-block;
  }
}
</style>
// <style lang="scss">
// .other-dialog .el-dialog__body{
//   padding: 0!important;
// }
</style>
