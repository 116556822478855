/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
// import pageClass from '@/unit/pageClass'

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.name = ''
      this.merchantId = ''
      this.state = ''
      this.time = []
      this.endTime = ''
      this.startTime = ''
      this.type = ''
      this.pageNum = 1
      this.pageSize = 12
      this.total = 0
    }
  }
}
export default searchFrom
