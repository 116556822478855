<!--
 * @Author: zhongtao
 * @LastEditors: zhongtao
-->
<template>
    <div class="dialog-container">
        <el-dialog
            title="效果数据"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            top="10vh"
            width="500px"
            >
            <el-form ref="form" class="detail-form" label-width="120px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="订单数:">
                            <div class="t-item">{{form.orderNum || 0}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总成交金额:">
                            <div class="t-item">{{form.totalMoney || 0}}元</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="销售产品数量:">
                            <div class="t-item">{{form.productNum || 0}}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="平均订单金额:">
                            <div class="t-item">{{form.avgMoney || 0}}元</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="参与活动人数:">
                            <div class="t-item">{{form.totalPerson || 0}}人</div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-cancel" @click="closeDialog">返回</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";

export default {
    data() {
    return {
        showFlag: false,
        form: {
            avgMoney: '',
            orderNum: '',
            productNum: '',
            totalMoney: '',
            totalPerson: '',
        },
    };
    },
    computed: {
        ...mapState(['DROPDOWNBOX', "TOKEN", "venueId", "venueList"]),
    },
    methods: {
        init() {
            this.form = {
                avgMoney: '',
                orderNum: '',
                productNum: '',
                totalMoney: '',
                totalPerson: '',
            }
        },
        // 弹框打开触发
        showDialog() {
            this.showFlag = true
        },
        getDetails(id) {
            this.$http.get(`${apis.activityperformanceData}?activityId=${id}`).then((res) => {
                if (res.data.code === 0) {
                    this.form = res.data.data
                    this.showDialog()
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.detail-form{
    .el-form-item{ margin-bottom: 0!important;}
    .t-item{ font-weight: bold; color: #333333;
        .card-fm{ height: 120px; width:150px;}
    }
    .line{width: 100%;height: 1px; background: #eeeeee; margin: 10px 0;}
}
.pb-20{ padding-bottom: 20px;}
</style>
